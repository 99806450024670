import { NavLink } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { colors, device, size } from './constants';
import vector from '../../assets/svg/vector.svg';
import { ReactComponent as CloseSvg } from '../../assets/svg/close.svg';
import { ReactComponent as InputCheckSvg } from '../../assets/svg/inputcheck.svg';
import { ReactComponent as InfoSvg } from '../../assets/svg/info.svg';
import { ReactComponent as SearchSvg } from '../../assets/svg/search.svg';
import NoAvatarSvg from '../../assets/svg/noavatar.svg';
import ButtonLoader from '../../assets/svg/button-loader.svg';
import Link from '../common/link';

// *********************** GLOBAL ***********************

export const smoothShow = `
    opacity: 0;
    visibility: hidden;
    animation: makeVisible 0ms forwards;
    animation-delay: 30ms;
`;

export const GlobalStyles = createGlobalStyle`

  html, body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //overflow: hidden;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
    min-width: ${size.laptop};
    font-family: 'Roboto', sans-serif;
    //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  //code {
  //   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
  //}

  div {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  button {
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    cursor: pointer;
  }

  em {
    font-style: italic;
  }

  b {
    font-weight: 500;
  }

  .xAxis .recharts-cartesian-axis-tick:last-child text {
    //transform: translate(-16px, 0);
  }

  .recharts-cartesian-axis.recharts-xAxis.xAxis {
    transform: translate(0, 5px);
  }

  .recharts-cartesian-axis.recharts-yAxis.yAxis {
    transform: translate(-20px, 0px);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 500000s;
    -webkit-text-fill-color: ${colors.blue7} !important;
  }

  input, textarea {
    outline: none;
  }

  ul, ol {
    list-style: none;
  }

  li {
    list-style: none;
  }

  .p-relative {
    position: relative;
  }

  .p-absolute {
    position: absolute;
  }

  .button {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.375rem 0.75rem;
    background-color: white;
    border: 1px solid ${colors.gray2};
    margin: 0;
    user-select: none;
    //border: none;
    //transition: all 0.2s;
    //box-shadow: 0 0 4px rgba(0,0,0,0.1);

    //&:hover, &:focus {
    //    box-shadow: 0 0 10px -2px rgba(0,0,0,0.25);
    //}

    &::before {
      position: absolute;
      visibility: hidden;
      z-index: -1000000;
      opacity: 0;

        //content: url(${ButtonLoader});
      content: "";
      margin-right: 5px;
      border-radius: 100%;
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      background-image: url(${ButtonLoader});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &.loading {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      cursor: default;
      box-shadow: none !important;
      transition: all 0s !important;
      pointer-events: none !important;
      opacity: 0.7;

      &::before {
        position: relative;
        visibility: visible;
        z-index: 1;
        opacity: 1;
        animation: spin 0.8s linear infinite;
      }
    }

    &:disabled {
      cursor: default;
      box-shadow: none !important;
      transition: all 0s !important;
      pointer-events: none !important;
      border: 1px solid ${colors.gray1} !important;
      background-color: ${colors.gray1} !important;
    }

    &.big {
      padding: 0.75rem 1.5rem;
    }

    &.with-icon {
      display: flex;
      align-items: center;
      text-transform: capitalize;

      svg {
        margin-left: 5px;
      }

      &.reverse {
        svg {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }


    &.blue {
      color: ${colors.blue1};
      border: 1px solid ${colors.blue1};

      &.filled {
        color: white;
        background-color: ${colors.blue1};
      }

      &:hover, &:focus {
        &:not(.loading).filled {
          background-color: ${colors.blue2};
        }
      }
    }

    &.black {
      background-color: ${colors.midnightBlue};
      border: 1px solid ${colors.midnightBlue};
      color: white;
      &:hover {
        background-color: ${colors.blue5};
        border: 1px solid ${colors.blue5};
      }
    }

    &.blue-dark {
      color: ${colors.blue5};
      border: 1px solid ${colors.blue5};

      &.filled {
        color: white;
        background-color: ${colors.blue5};
      }

      &:hover, &:focus {
        &:not(.loading).filled {
          background-color: ${colors.blue4};
        }
      }
    }
    &.midnight-blue {
      color: ${colors.midnightBlue};
      border: 1px solid ${colors.midnightBlue};

      &.filled {
        color: white;
        background-color: ${colors.midnightBlue};
      }

      &:hover, &:focus {
        &:not(.loading).filled {
          background-color: ${colors.midnightBlue};
        }
      }
    }

    &.green {
      color: ${colors.green1};
      border: 1px solid ${colors.green1};

      &.filled {
        color: white;
        background-color: ${colors.green1};
      }

      &:hover, &:focus {
        &:not(.loading).filled {
          background-color: ${colors.green2};
        }
      }
    }

    &.red {
      color: ${colors.red1};
      border: 1px solid ${colors.red1};

      &.filled {
        color: white;
        background-color: ${colors.red1};
      }

      &:hover, &:focus {
        &:not(.loading).filled {
          background-color: ${colors.red2};
        }
      }
    }

    &.gray {
      color: ${colors.blue6};
      border: 1px solid ${colors.gray2};

      &.filled {
        color: white;
        background-color: ${colors.gray2};
      }

      &:hover, &:focus {
        &:not(.loading).filled {
          background-color: ${colors.gray2};
        }
      }
    }
    
    &.gray-filled {
      background-color: ${colors.gray8};
      color: ${colors.blue6};
      border: 1px solid ${colors.gray8};
      &:hover, &:focus {
        background-color: ${colors.gray2};
        border: 1px solid ${colors.gray2};
      }
    }
      
    &.inline {
        border: none !important;
    }
      
    &.transparent {
        background-color: transparent;
    }

    @media ${device.tablet} {
      font-size: 0.812rem;
      line-height: 1.25rem;
      padding: 0.375rem 0.625rem;
    }
  }

  .animateRotate {
    animation: spin 0.8s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .nowrap {
    white-space: nowrap;
  }

  .unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .tableLink {
    text-decoration: underline;
  }

  .smooth-show {
    ${smoothShow}
  }

  .my-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .monospace {
    font-family: monospace !important;
  }

  .f-size-13 {
    font-size: 13px !important;
  }

  @keyframes makeVisible {
    0% {
      opacity: 0;
      visibility: visible;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  .show-suggestion-popup {
    height: 40px;
  }

  .hide-suggestion-popup {
    height: 0;
  }
}
`;

// *********************** GLOBAL ***********************

export const Body = styled.div`
  width: ${props => (props.isNotFound ? '100%' : props.showSidebar ? 'calc(100% - 250px)' : 'calc(100% - 60px)')};
  background-color: ${colors.gray5};

  @media ${device.tablet} {
    height: calc(100vh - 54px);
  }
`;

export const MainContainer = styled.div`
  display: flex;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
  width: ${props => props.width};
  height: ${props => props.height};
  min-height: ${props => props.minHeight};
  padding: ${props => props.padding};
  padding-left: ${props => props.paddingLeft};
  flex-wrap: ${props => props.flexWrap};
  text-align: ${props => props.textAlign};;
  text-transform: ${props => props.textTransform};
  gap: ${props => props.gap};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  padding-right: ${props => props.paddingRight};
  padding-left: ${props => props.paddingLeft};
  padding: ${props => props.padding};
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: ${props => props.backgroundColor};
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};
  text-transform: ${props => props.textTransform};
  gap: ${props => props.gap};
`;

//

export const H2 = styled.h2`
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 500;
`;

export const H3 = styled.h3`
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 500;
  margin-top: 30px;
`;

export const H4 = styled.h4`
  font-size: 0.812rem;
  line-height: 0.937rem;
  font-weight: 600;
  margin: 0;
`;

//

export const Button = styled.button`
  width: ${props => props.width};
  height: ${props => props.height};
  border: none;
  background: ${props => props.background};
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${props => props.color};
  margin-top: ${props => props.marginTop};
  cursor: pointer;
`;

export const PlusButton = styled.button`
  &.button {
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      margin-right: 10px;
    }

      // @media ${device.tablet} { 
    //     padding: 8px 10px;
    //    
    //     &::after {
    //       content: 'Create';
    //     }
    //    
    //     svg {
    //        display: none;
    //     }
    //    
    //     span { display: none }
    // }
  }
`;

export const Input = styled.input`
  height: 40px;
  min-height: 40px;
  width: 100%;
  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};
  background: #FFFFFF;
  border: ${props => (props.isError ? `1px solid ${colors.red1}` : `1px solid ${colors.gray2}`)};
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 10px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.blue7};
  margin-top: ${props => props.marginTop};

  ::placeholder {
    color: #A5A7B1;
  }

  &:disabled {
    background-color: ${colors.gray0};
  }

  @media ${device.tablet} {
    min-width: calc(100% - 30px);
  }
`;

export const Select = styled.select`
  height: 40px;
  min-height: 40px;
  width: 100%;
  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};
  background: #FFFFFF;
  border: ${props => (props.isError ? `1px solid ${colors.red1}` : `1px solid ${colors.gray2}`)};
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 10px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.blue7};
  margin-top: ${props => props.marginTop};

  //option:first-of-type {
  //    display: none;
  //}

  &.default-selection:first-of-type {
    color: #A5A7B1;
  }

  @media ${device.tablet} {
    min-width: calc(100% - 30px);
  }
`;

export const CustomSelect = styled.div`
  display: flex;
  height: 40px;
  min-height: 40px;
  width: 100%;
  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};
  background: #FFFFFF;
  border: ${props => (props.isError ? `1px solid ${colors.red1}` : `1px solid ${colors.gray2}`)};
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.blue7};
  margin-top: ${props => props.marginTop};

  ::placeholder {
    color: #A5A7B1;
  }

  &:disabled {
    background-color: ${colors.gray2};
  }

  .dropdown-wrapper {
    width: 100%;
  }

  .dropdown-body {
    width: 100%;
  }

  .dropdown-trigger {
    & .default-selection {
      color: #A5A7B1;
    }

    //width: calc(100% - 20px);

    &::after {
      border-color: black;
    }
  }
`;

export const CustomSelectTrigger = styled.span`
  display: block;
  width: calc(100% - 20px);
  padding: 8px 10px 8px 15px;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.blue7};
  text-transform: ${props => props.textTransform};
`;

export const Label = styled.label`
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 0.812rem;
    line-height: 0.937rem;
    font-weight: 500;
    margin: ${props => props.margin ?? 0};
    color: ${colors.blue6};
`;

export const DropdownOptionsUl = styled.ul`
  ${props => (props.isCoutryList && `   
    height: 200px;
    overflow: scroll;
  `)};
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const DropdownOptionLi = styled.li`
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.blue6};
  padding: ${options => (options.padding ? options.padding : '6px 6px 6px 25px')};

  &:hover {
    background: #EFF1FF;
  }
`;

export const ArrowBackLink = styled(Link)`
  padding: 8px 10px 4px 0;
`;

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => props.marginTop || '10px'};
  justify-content: ${props => props.justify || 'flex-end'};
  height: ${props => props.height};
  min-height: ${props => props.height};

  margin-left: -4px;
  margin-right: -4px;

  a, button {
    min-width: 100px;
    margin: 0 4px;
  }
`;
//

export const Tooltip = styled.div`
  position: absolute;
  visibility: hidden;
  top: -15px;
  left: -20px;
  z-index: 1;
`;

export const TooltipBody = styled.div`
  position: relative;
  width: ${props => props.width || '240px'};
  height: ${props => props.height};
  top: 40px;
  left: -15px;
  padding: 12px 12px 11px 17px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0.8px 0.8px 0 rgba(31, 35, 60, 0.06), 0 0 2px rgba(31, 35, 60, 0.08), 0 2px 6px rgba(31, 35, 60, 0.16);

  &::before {
      //background-image: url(${vector});
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    top: -8px;
    left: 39px;
    border-radius: 4px;
    transform: rotate(45deg);
    background-color: white;
  }
`;

export const SvgTooltip = styled.span`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: ${colors.blue7};

  a {
    color: ${colors.blue1};
    text-decoration: underline;
  }
`;

export const SvgInfoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  &:hover ${Tooltip} {
    visibility: visible;
  }
`;

export const StyledInfoSvg = styled(InfoSvg)`
  position: absolute;
  cursor: pointer;
  padding-left: 4px;
  height: 13px;
`;

//

export const Title = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin: 0 0 20px 0;
  color: ${colors.blue6};

  @media ${device.tablet} {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;

export const TitleDescription = styled.span`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.5rem;
  color: ${colors.blue7};
  text-align: center;
  white-space: pre-line;
  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

//

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => (props.isNotBackground ? '' : 'rgba(23, 34, 104, 0.16)')};

  ${props => (!props.notSmooth && smoothShow)};

    // @media ${device.tablet} { 
  //     align-items: flex-start;
  // }
`;

export const StyledCloseSvg = styled(CloseSvg)`
  position: absolute;
  top: ${props => props.top || '8px'};
  right: ${props => props.right || '5px'};;
  cursor: pointer;
  padding: 15px;
`;

export const Popup = styled.div`
  position: relative;
  min-width: ${props => props.minWidth || '400px'};
  max-width: ${props => props.maxWidth || '600px'};
  min-height: ${props => props.minHeight || '150px'};
  max-height: ${props => props.maxHeight || '650px'};
  width: ${props => props.width || 'unset'};
  height: ${props => props.height || 'unset'};
  display: flex;
  flex-direction: column;
  padding: ${props => (props.padding ? props.padding : '22px 24px')};
  background: #FFFFFF;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.06);
  border-radius: ${props => props.borderRadius || '4px'};

  ${ButtonBlock} > button {
    height: 40px;
  }

  ${props => (props.isLoading && `
        user-select: none;
        
        & > * {
           pointer-events: none;
           user-select: none;
        }
        
         ${ButtonBlock} {
          a, button {
            pointer-events: none;
            user-select: none;
            opacity: 0.7;
          }
        }
    `)};
`;

export const PopupName = styled.span`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${colors.blue6};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  word-break: break-word;

  ${props => {
    if (props.isDelete) {
      return `
            max-width: calc(100% - 20px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        `;
    }

    return '';
  }}

`;

export const PopupDescription = styled.span`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.blue7};
  cursor: default;
  white-space: pre-line;
  margin-top: ${props => props.marginTop || '10px'};
  margin-bottom: ${props => props.marginBottom || '10px'};
  word-break: break-word;

  .highlight {
    text-decoration: underline;
    font-weight: 500;
    color: ${colors.blue1};
  }
`;

export const PopupSubHeader = styled.span`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.blue6};
  margin-top: ${props => props.marginTop || '24px'}
`;

//

export const ErrorMessage = styled.span`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: ${colors.red1};
  margin-left: 5px;
  margin-top: 2px;
  min-height: 12px;
`;

//

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
  padding: 20px 30px;

  ${smoothShow} // TODO -> test it better

  ${props => props.isHidden && `
        display: none;
    `} @media ${device.tablet} {
    padding: 10px;
  }
`;

export const PageHeader = styled.span`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  //text-transform: capitalize;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: ${props => props.marginBottom};
  color: ${colors.blue6};

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 28px;
  }
`;

// export const PageSubHeaderContainer = styled.div`
//     display: flex;
//     width: 100%;
//     height: 50px;
//     border-bottom: 1px solid ${colors.gray1};
//     justify-content: space-between;
//     align-items: center;
//     padding: 0 24px;
// `;

// export const PageSubHeader = styled.span`
//     font-family: 'Roboto', sans-serif;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 18px;
//     line-height: 21px;
//     color: #1F233C;
// `;

export const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  background: #FFFFFF;
  border: 1px solid ${colors.gray1};
  box-sizing: border-box;
  border-radius: 8px;
`;

export const PageDescription = styled.span`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: ${colors.blue7};

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const PageBodyContainer = styled.div`
  width: 100%;
  padding: 15px 25px;
  background: white;
  box-sizing: border-box;
  border-style: solid;
  border-color: #D7D8E3;
  border-width: 0;

  ${props => props.positionType === 'mid' && `
        border-width: 1px 1px 0 1px;
    `}

  ${props => props.positionType === 'bottom' && `
        border-width: 1px;
    `} @media ${device.tablet} {
    padding: 15px;
  }
`;

export const PageBodyContainerScroll = styled.div`
  width: 100%;
  height: ${props => (props.height ? props.height : 'fit-content')};
  background: ${props => props.background || 'white'};
  border: ${props => props.border || `1px solid ${colors.gray2}`};
  border-radius: ${props => props.borderRadius || '8px'};
  margin-top: ${props => props.marginTop || '15px'};
  overflow: ${props => props.overflow || 'auto'};

  box-sizing: border-box;
  //border-radius: 8px;
  //display: flex;
  //flex-direction: column;
  //align-items: center;

  ${props => props.isLoading && `
        cursor: default;
        pointer-events: none !important;    
    `}

  ${props => props.fullHeight && `
        height: 100%;
    `} ${props => props.positionType === 'top' && `
        margin-top: 0; 
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    `} ${props => props.positionType === 'mid' && `
        margin-top: 0; 
        border-radius: 0px;
    `} ${props => props.positionType === 'bottom' && `
        margin-top: 0; 
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    `} @media ${device.tablet} {
    margin-top: ${props => (props.marginTop ? props.marginTop : !props.positionType && '10px')};
  }
`;

//

export const StyledSelect = styled.div`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  background: ${props => (props.isSelected ? colors.blue1 : '#FFFFFF')};
  border-radius: 50%;
  border: ${props => `1px solid ${props.isSelected ? colors.blue1 : colors.gray1}`};
  box-sizing: border-box;
  box-shadow: inset 0 2px 2px rgba(56, 59, 81, 0.1);
`;

export const StyledInputCheckSvg = styled(InputCheckSvg)`
  position: absolute;
  top: 2px;
  left: 1px;
`;

export const TextBlue = styled.span`
  color: ${colors.blue1};
  font-weight: 500
`;

export const TextVisibleOnHover = styled.div`
  position: relative;
  max-width: 100%;
  white-space: nowrap;
  //text-overflow: ellipsis;
  //overflow: hidden; 

  &::after {
    content: ${props => `"${props.text}"`};
    z-index: 1000;
    display: none;
    position: absolute;
    left: -6px;
    bottom: 10px;
    transform: translateY(100%);
    //max-width: 600px; // TODO -> better option
    overflow: hidden;
    font-size: 0.937rem;
    padding: 6px;
    color: black;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 0 1px ${colors.gray2}, 0 0 10px 0px rgba(0, 0, 0, 0.15);
  }

  &:hover {
    //overflow: visible;
    //max-width: unset;
    //background-color: white;
    //box-shadow: 0 0 0 10px white, -10px 0 0 10px white, 10px 0 0 10px white;

    &::after {
      display: block;
    }
  }
`;

export const LoadingDots = styled.p`

  position: relative;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  height: 16px;
  color: ${colors.blue6};
  margin-top: ${props => props.marginTop || '6px'};
  margin-bottom: 0;

  &::after {
    content: ' .';
    color: black;
    animation: loadingDots 1s infinite;
  }

  @keyframes loadingDots {
    0%, 20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: .25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: black;
      text-shadow: .25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: .25em 0 0 black, .5em 0 0 rgba(0, 0, 0, 0);
    }
    80%, 100% {
      text-shadow: .25em 0 0 black, .5em 0 0 black;
    }
  }
`;

//

export const TableWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  overflow: auto;
  width: 100%;
  //min-width: 900px;
  max-height: 100%;
  height: 100%;

  table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    thead {
      height: 50px;

      tr {
        td {
          position: sticky;
          z-index: 1;
          top: 0;
          height: 50px;

          overflow: hidden;
          word-wrap: break-word;
          background-color: ${colors.gray0};

          & > * {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          box-sizing: border-box;
          min-width: 100px;
          padding: 0 12px;

          &.options {
            text-align: right;
            border-right: none;
            min-width: 200px;
            box-shadow: none;
          }
        }

        td:first-child {
          padding-left: 24px;
        }

        td:last-child {
          padding-right: 24px;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid ${colors.gray1};
        box-sizing: border-box;
        vertical-align: top;

        td {
          position: relative;
          box-sizing: border-box;
          padding: 5px 12px;
          min-width: 100px;

          //white-space: nowrap;
          //text-overflow: ellipsis;
          //overflow: hidden;
          //word-wrap: break-word;

          &:hover {
            .tool {
              display: block;
            }
          }

          .tool {
            display: none;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            pointer-events: none;
            background-color: black;
            color: white;
            transform: translate(10px, calc(8px));
            font-size: 13px;
            font-weight: 500;
            padding: 8px 10px 10px 8px;
            border-radius: 4px;

            max-width: 600px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &::before {
              content: "";
              position: absolute;

            }
          }

          & > * {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .options {
            button:first-of-type {
              border-left: none;
            }
          }

          .options.member_role {
            padding-top: 1px;

            .dropdown-wrapper {
              display: flex;
              width: 140px;
              max-width: 100%;
              background: #FFFFFF;
              border: 1px solid #D7D8E3;
              box-sizing: border-box;
              border-radius: 4px;
              padding: 7px 0 7px 12px;
            }

            .dropdown-trigger {
              text-align: left;
              font-size: 15px;
              line-height: 16px;
            }
          }

          &.td_with_drop {
            & > * {
              overflow: initial;
            }
          }

          &.table_message_row button {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 8px 0;
            text-align: left;
            > p {
              display: inline;
            }
          }
        }

        td:first-child {
          padding-left: 24px;
        }

        td:last-child {
          padding-top: 7px;
          padding-right: 24px;

          & > div {
            overflow: inherit;
          }
        }

        &:last-of-type {
          border-bottom: none;
          min-width: 200px;
        }
      }
    }
  }
`;

export const TableTextLink = styled.button`
  border: none;
  background-color: initial;
  color: ${colors.blue7};
  font-size: 15px;
  line-height: 44px;
  padding: 0 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: left;
`;

export const TableBodyText = styled.p`
  font-size: 15px;
  line-height: 1.25rem;
  padding: 0.5rem 0;
  margin: 0;
  cursor: default;
  color: ${colors.blue7};
  display: flex;
  align-items: center;

  & > .tableLink {
    cursor: pointer;
  }

  a {
    color: ${colors.blue1};
  }

  @media ${device.laptopL} {
    font-size: 14px;
  }
`;

export const TableHeadText = styled.p`
  font-size: 15px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${colors.blue6};
  @media ${device.laptopL} {
    font-size: 14px;
  }
`;

export const TableOptions = styled(Row)`
  padding-right: 15px;
  //display: flex;
  //align-items: center;
  //justify-content: flex-end;

  button:first-of-type {
    border-left: none;
  }
`;

export const TableOption = styled(Button)`
  font-weight: normal;
  font-size: 0.937rem;
  color: ${colors.blue1};
  text-transform: capitalize;
  border-left: 1px solid ${colors.gray1};
  border-radius: 0;
  padding: ${props => (props.loadingButton ? '5px 10px' : '0.5rem 0 0.5rem 10px')};
  margin-left: 10px;
  background: transparent;
`;


export const StyledSearchSvg = styled(SearchSvg)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

export const SearchField = styled.input`
  width: 200px;
  padding: 8px 0 8px 36px;
  border: ${`1px solid ${colors.gray2}`};
  border-radius: 4px;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
`;

export const FilterField = styled.div`
  border: ${`1px solid ${colors.gray2}`};
  border-radius: 4px;
  background-color: transparent;
  margin-left: 12px;

  .dropdown-trigger::after {
    border-color: black;
  }
`;

export const FilterFieldSpan = styled.span`
  display: block;
  width: 100px;
  padding: 8px 0 8px 8px;
  text-align: left;
  text-transform: capitalize;
  font-size: 0.875rem;
  line-height: 1rem;
`;

export const FilterTitle = styled.span`
  font-size: 0.812rem;
  font-weight: 500;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
  padding: 15px 25px;
  margin: 0 !important;
`;

export const OptionRadioWrapper = styled(DropdownOptionLi)`

  //padding: 0;
  //    padding: 8px 25px;


  & > div {
    //padding: 8px 25px;
    margin-bottom: 0;

    label {
      width: 100%;
      margin-bottom: 0;
      text-transform: capitalize;
    }
  }
`;

export const FiltersDropdown = styled(DropdownOptionsUl)`
  padding-bottom: 10px;
`;

export const Avatar = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 100%;
  //background-image: ;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
  transition: background-color 0.4s;

  ${props => (props.noMargin && 'margin-right: 0;')};

  ${props => (!(props.imageUrl || props.color) && `
        background-size: 26px;
        background-color: ${colors.blue3};
        background-image: url(${NoAvatarSvg})
    `)};

  ${props => (props.imageUrl && `
        background-image: url(${props.imageUrl})
    `)}
  
  ${props => (props.color && `
        background-color: ${props.color};
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        font-weight: 500;
        font-size: 1rem;
        line-height: 15px;
        text-transform: capitalize; 
        color: white;
     `)}
  
  ${props => props.size && props.size === 'm' && `
        margin-right: 14px;
        width: 50px;
        min-width: 50px;
        height: 50px;
        min-height: 50px;
        font-size: 1.5rem;
    `};

  ${props => props.size && props.size === 'l' && `
        margin-right: 18px;
        width: 80px;
        min-width: 80px;
        height: 80px;
        min-height: 80px;
        font-size: 2rem;
    `};
`;

export const AvatarDeleteButton = styled(CloseSvg)`
  position: absolute;
  box-sizing: border-box;
  top: 0;
  right: 0;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 100%;
  padding: 3px;
  background-color: #A3A5B0;
  border: 2px solid white;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #6f7480;
  }
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.gray4};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  ${props => props.isactiveitem === 'true' && `
                color: ${colors.blue6};
                position: relative;
                &::before {
                    content: "";
                    width: 100%;
                    height: 2px;
                    position: absolute;
                    bottom: -22px;
                    left: 0;
                    background-color: ${colors.blue1};
                }
            `
  }
`;

export const StyledNav = styled.nav`
  display: flex;
  max-width: 426px;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  padding-left: 24px;
`;

export const StyledNavItem = styled.span`
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${props => (props.activeColor ? colors.blue6 : colors.gray4)};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  cursor: pointer;
  ${props => props.isActiveItem === 'true' && `
                color: ${colors.blue6};
                position: relative;
                &::before {
                    content: "";
                    width: 100%;
                    height: 2px;
                    position: absolute;
                    bottom: -20px;
                    z-index: 2;
                    border-radius: 4px;
                    left: 0;
                    background-color: ${colors.blue1};
                }
            `
  }
  ${props => props.disable && `
                pointer-events: none;
            `
  }
`;

export const ItemNote = styled.div`
  display: none;
  position: absolute;
  z-index: 301;
  padding: 10px 12px;
  background-color: ${colors.blue6};
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 600;
  color: white;
  pointer-events: none;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    background-color: ${colors.blue6};
    border-radius: 1px;
    width: 14px;
    height: 14px;

    ${props => props.direction === 'right' && `
            left: -5px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
        `} ${props => props.direction === 'top' && `
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
        `}
  }

  ${props => props.visible && `
       display: block;
    `} ${props => props.direction === 'right' && `
        top: 50%;
        left: calc(100% + 30px);
        transform: translateY(-50%);
    `} ${props => props.direction === 'top' && `
        bottom: calc(100% + 15px);
        left: 50%;
        transform: translateX(-50%);
    `} ${props => props.disabled && `
        color: ${colors.gray4};
    `}
`;

export const CopyButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-45%);
  background-color: transparent;
  border: none;
  padding: 10px;
`;

export const CopyNote = styled(ItemNote)`
  display: block;
  opacity: 0;

  animation: ${props => props.hideIt && 'hideIt 0.8s ease forwards'};
  animation-delay: 0.1s;

  @keyframes hideIt {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

//

export const ItemList = styled(Row)`
  flex-wrap: wrap;

  & > * {
    min-width: 200px;
    margin-right: 40px;
    margin-bottom: 20px;
    font-size: 0.875rem;
  }
`;

export const GroupName = styled(H4)`
  font-size: 0.812rem;
  margin-bottom: 1rem;
  color: ${colors.blue6};
`;

export const GroupItem = styled.div`
  margin-bottom: 5px;
  color: ${colors.blue7};

  ${props => props.noMargin && 'margin-bottom: 0;'}
`;

//

// export const PageContainerDescription = styled.span`
//     font-family: 'Roboto', sans-serif;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 15px;
//     line-height: 24px;
//     color: ${colors.blue7};
//     padding: 18px;
// `;

export const ChartTooltip = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  visibility: hidden;
  opacity: 0;

  &:hover {
    visibility: visible;
    opacity: 1;
  }
`;

export const ChartTooltipBody = styled.div`
  cursor: default;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 0 2px rgba(31, 35, 60, 0.08), 0 2px 4px rgba(31, 35, 60, 0.12);
  border-radius: 4px;

  min-width: 150px;
  min-height: 120px;
`;

export const ChartTooltipValue = styled.p`
  position: relative;
  padding-left: 15px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375rem;
  margin: 0;

  &::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    ${props => props.color && `background-color: ${props.color};`}
  }`;

export const ChartTooltipSection = styled.div`
  background-color: white;

  span {
    font-size: 0.812rem;
    line-height: 1.5rem;
    font-weight: 400;
    white-space: nowrap;
  }

  margin-bottom: ${props => props.marginBottom};
`;

export const LoaderContainer = styled.div`
    position: relative;
    width: 100%;
    height: 30px;
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '40px')};
    margin-top: ${props => (props.marginTop ? props.marginTop : '40px')};
`;