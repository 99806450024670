export const customerDataSelector = store => {
    if (store.customerData) {
        return store.customerData;
    }
    return null;
};

export const customerPropsSelector = store => store.customerData.customer;

export const customerSuggestionForTwoFactorSelector = store => store.customerData.suggestionsForTwoFactor;

export const customerSubscriptionSelector = store => {
    const { customer } = store.customerData;
    if (customer.id === customer.selectedCustomerId) {
        if (customer.subscription) {
            return customer.subscription;
        }
    } else if (customer.affiliations) {
        const selectedCustomer = customer.affiliations.find(item => item.id === customer.selectedCustomerId);
        return selectedCustomer.subscription;
    }
    return null;
};

export const customerSubscriptionHistorySelector = store => {
    const { subscriptionHistory, subscriptionHistoryLoading } = store.customerData;
    return {
        subscriptionHistory,
        subscriptionHistoryLoading
    };
};

export const customerUsageInPeriodSelector = store => {
    const { activeUsersForCurrentPeriod, storageUsageForCurrentPeriod, usageInPeriodLoading } = store.customerData;
    return {
        activeUsersForCurrentPeriod,
        storageUsageForCurrentPeriod,
        usageInPeriodLoading
    };
};
export const paymentInfoSelector = store => {
    const { paymentInfo, paymentInfoIsFetched } = store.customerData;
    return {
        paymentInfo,
        paymentInfoIsFetched
    };
};

export const paymentInvoicesSelector = store => {
    const { paymentInvoices, paymentInvoicesIsFetched } = store.customerData;
    return {
        paymentInvoices,
        paymentInvoicesIsFetched
    };
};

export const changeSubscriptionPlanLoading = store => store.customerData.subscriptionChangeLoading;

export const requestsLimitSelector = store => store.customerData.requestsLimit;