import React from 'react';
import { ReactComponent as OverviewSvg } from '../assets/svg/overview.svg';
import { ReactComponent as AppsSvg } from '../assets/svg/apps.svg';
import { ReactComponent as UsersSvg } from '../assets/svg/usersicon.svg';
import { ReactComponent as ChannelsSvg } from '../assets/svg/channels.svg';
import { ReactComponent as UsageSvg } from '../assets/svg/usage.svg';
import { ReactComponent as ModerationSvg } from '../assets/svg/moderation.svg';
// import { ReactComponent as BillingSvg } from '../assets/svg/billing.svg';
// import { ReactComponent as GetHelpSvg } from '../assets/svg/gethelp.svg';


export const androidAuthAppUrl = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru';
export const iosAuthAppUrl = 'https://apps.apple.com/ru/app/google-authenticator/id388497605';

export const cancelSubscriptionOptions = [
    {
        id: 1,
        name: 'Current plan doesn’t do what i expected'
    },
    {
        id: 2,
        name: 'Important features are missing'
    },
    {
        id: 3,
        name: 'Sceyt is too expensive'
    },
    {
        id: 4,
        name: 'There were technical difficulties'
    },
    {
        id: 5,
        name: 'I no longer need it'
    },
    {
        id: 6,
        name: 'Other'
    }
];

export const routeNames = {
    OVERVIEW: 'overview',
    APPS: 'apps',
    USERS: 'users',
    CHANNELS: 'channels',
    MEMBERS: 'members',
    INVITATIONS: 'invitations',
    // ROLES: 'roles',
    USAGE: 'usage',
    MODERATION: 'moderation',
    BILLING: 'billing',
    GET_HELP: 'getHelp',
    SETTINGS: 'settings',
    SECURITY: 'security',
    USER_ACCOUNT: 'user-account'
};

export const subRoutNames = {
    GENERAL: 'general',
    APPLICATION: 'application',
    ROLES: 'roles',
    MARKERS: 'markers',
    NOTIFICATIONS: 'notifications',
    API_KEYS: 'api-keys',
    WEB_HOOKS: 'webhooks',
    SUBSCRIPTION: 'subscription',
    PLANS: 'plans',
    BILLING_DETAILS: 'billing-details',
    INVOICES: 'invoices',
    NEW_ROLE: 'new',
    ROLE: ':roleId',
    ROLE_EDIT: 'edit',
    ROLE_DUPLICATE: 'duplicate',
    SECURITY: 'security',

    ACTIVE_MEMBERS: 'active-members',
    BLOCKED_MEMBERS: 'blocked-members'
};

export const menuArray = [
    {
        to: routeNames.APPS,
        svg: <AppsSvg />,
        text: 'Applications'
    },
    {
        to: routeNames.OVERVIEW,
        svg: <OverviewSvg />,
        text: 'Overview'
    },
    {
        to: routeNames.USERS,
        svg: <UsersSvg />,
        text: 'Users'
    },
    {
        to: routeNames.CHANNELS,
        svg: <ChannelsSvg />,
        text: 'Channels'
    },
    /* {
        to: routeNames.ROLES,
        svg: <RolesSvg/>,
        text: 'Roles'
    }, */
    {
        to: routeNames.USAGE,
        svg: <UsageSvg />,
        text: 'Usage'
    },
    {
        to: routeNames.MODERATION,
        svg: <ModerationSvg />,
        text: 'Moderation'
    }
    // {
    //     to: routeNames.BILLING,
    //     svg: <BillingSvg/>,
    //     text: 'Billing'
    // },
    // {
    //     to: routeNames.GET_HELP,
    //     svg: <GetHelpSvg/>,
    //     text: 'Get Help'
    // }
];

export const defaultRoleNames = [
    'owner',
    'participant',
    'admin',
    'subscriber'
];

export const requestFails = {
    1001: {
        popupName: '',
        buttonName: '',
        actionName: '',
        code: ''
    }
};

export const pageRequestLimit = 25; // TODO -> test for big data
export const popupRequestLimit = 40;

export const filterMethods = {
    CONTAINS: 'contains',
    ENDS: 'ends',
    EQUAL: 'eq',
    NOT_EQUAL: 'not_eq',
    IN: 'in',
    BEGINS: 'begins'
};

export const userFilterOptions = [
    {
        name: 'All',
        option: 'all'
    },
    {
        name: 'ID',
        option: 'id'
    },
    {
        name: 'First name',
        option: 'first_name'
    },
    {
        name: 'Last name',
        option: 'last_name'
    }
];

export const userSearchMethods = {
    ID: 'id',
    FIRST_NAME: 'first_name',
    LAST_NAME: 'last_name',
    ALL: 'id,first_name,last_name'
};

export const USER_STATE = {
    ACTIVE: 'active',
    DEACTIVATED: 'deactivated'
};

export const FREE_SUBSCRIPTION_IDS = new Set([0, 10, 11, 12, 13]);

export const subscriptionState = {
    ACTIVE: 'active',
    TRIAL: 'trial',
    CANCELED: 'canceled'
};

export const CUSTOMER_STATE = {
    pending: 0,
    active: 1,
    suspended: 2
};

export const userFilterOptionsTest = [
    {
        name: 'All',
        option: userSearchMethods.ALL
    },
    {
        name: 'ID',
        option: userSearchMethods.ID
    },
    {
        name: 'First name',
        option: userSearchMethods.FIRST_NAME
    },
    {
        name: 'Last name',
        option: userSearchMethods.LAST_NAME
    }
];

export const channelFilterMethods = {
    TYPE: 'type'
};

export const channelSearchMethods = {
    SUBJECT: 'subject'
};

export const reportsFilters = {
    STATUS: 'status'
};

export const reportsDataState = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED'
};

export const reportsResourceType = {
    CHANNELS: 'CHANNELS',
    MESSAGES: 'MESSAGES',
    USERS: 'USERS'
};

export const reportsFilterOptions = {
    PENDING: 'open',
    APPROVED: 'resolved',
    REJECTED: 'rejected'
};

export const permissionGroups = [
    {
        name: 'Channels',
        list: {
            deleteChannel: 'Delete channel',
            updateChannel: 'Update channel',
            freezeChannel: 'Freeze channel',
            addMember: 'Add member',
            getMembers: 'Get members',
            kickMember: 'Kick member',
            kickAndBlockMember: 'Kick and block member',
            changeMemberRole: 'Change member role'
        }
    },
    {
        name: 'Messages',
        list: {
            // readMessage: 'Read message',
            sendMessage: 'Send message',
            sendAttachmentMessage: 'Send attachment message',
            replyMessage: 'Reply message',
            forwardMessage: 'Forward message',
            mentionMember: 'Mention member',
            editOwnMessage: 'Edit own message',
            editAnyMessage: 'Edit any message',
            deleteOwnMessage: 'Delete own message',
            deleteAnyMessage: 'Delete any message',
            clearAllMessages: 'Delete all messages',
            pinMessage: 'Pin message'
            // mentionMember: 'Mention member' // TODO -> disabled for Version 1.0
        }
    },
    {
        name: 'Reactions',
        list: {
            addMessageReaction: 'Add message reaction',
            deleteOwnMessageReaction: 'Delete own message reaction',
            deleteAnyMessageReaction: 'Delete any message reaction'
        }
    }
];

export const webhookEventsGroups = [
    {
        name: 'Channel',
        list: {
            'channel.created': 'Created',
            'channel.updated': 'Updated',
            'channel.deleted': 'Deleted',
            'channel.event.sent': 'Event sent',
            'channel.messages.pinned': 'Messages pinned',
            'channel.messages.unpinned': 'Messages unpinned',
            'channel.messages.deleted': 'Messages deleted'
        }
    },
    {
        name: 'Members',
        list: {
            'channel.members.added': 'Added',
            'channel.members.updated': 'Updated',
            'channel.members.kicked': 'Kicked',
            'channel.users.blocked': 'Kicked and blocked',
            'channel.member.left': 'Left'
        }
    },
    {
        name: 'Message',
        list: {
            'message.presend': 'Pre-Send',
            'message.sent': 'Sent',
            'message.edited': 'Edited',
            'message.deleted': 'Deleted',
            'message.reaction.added': 'Reaction added',
            'message.reaction.removed': 'Reaction removed',
            'message.marker.added': 'Marker added'
        }
    },
    {
        name: 'User',
        list: {
            'user.connected': 'Connected',
            'user.disconnected': 'Disconnected',
            'user.blocked': 'Blocked',
            'user.unblocked': 'Unblocked'
        }
    }
];

export const SENDING_STATE = {
    NONE: 'none',
    SENDING: 'sending',
    SENT: 'sent',
    FAILED: 'failed'
};