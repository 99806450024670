import React, { useState } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { APPS_CALL, setSelectedApp } from '../../store/apps/actions';
import { appNamesSelector, appsDataSelector, selectedAppNameSelector } from '../../store/apps/selectors';
import { customerDataSelector, customerSubscriptionSelector } from '../../store/customer/selectors';
import CreateEditApp from '../pages/apps/app-create';
import ErrorWrapper from '../complex/error-wrapper';
import Link from '../common/link';
import DropDown from '../common/dropdown';
import { colors, device } from '../UI/constants';
import { routeNames, subRoutNames, subscriptionState } from '../../helpers/constants';
import { CUSTOMER_CALL } from '../../store/customer/actions';
import { Row, Col, DropdownOptionsUl, DropdownOptionLi, Avatar } from '../UI';
import { ReactComponent as PlusSvg } from '../../assets/svg/plus.svg';
import { ReactComponent as ArrowRight } from '../../assets/svg/arrowRight.svg';
import { ReactComponent as AppSvg } from '../../assets/svg/apps.svg';
import { ReactComponent as OpenArrowSvg } from '../../assets/svg/arrowDown.svg';
import { ReactComponent as CheckIcon } from '../../assets/svg/done.svg';
import { ReactComponent as SwitchIcon } from '../../assets/svg/switch.svg';
import { ReactComponent as ProfileIcon } from '../../assets/svg/profile.svg';
import { ReactComponent as PaymentIcon } from '../../assets/svg/payment.svg';
import { ReactComponent as SupportIcon } from '../../assets/svg/support.svg';
import { ReactComponent as UsersIcon } from '../../assets/svg/users.svg';
import { ReactComponent as SecurityIcon } from '../../assets/svg/security.svg';

import {
    resetAffiliationMemberStore,
    setActiveParentCustomerCall
} from '../../store/members/actions';


export default function Header() {

    const dispatch = useDispatch();
    const { isFetched: customerIsFetched, customer } = useSelector(customerDataSelector, isEqual);
    const [showCreatePopup, setCreatePopupShow] = useState(false);
    const userAppNames = useSelector(appNamesSelector, isEqual);
    const { isFetched } = useSelector(appsDataSelector, isEqual);
    const selectedAppName = useSelector(selectedAppNameSelector, isEqual);
    const customerSubscription = useSelector(customerSubscriptionSelector, isEqual);

    let selectedCompanyName;
    if (customer.selectedCustomerId === customer.id) {
        selectedCompanyName = customer.company && customer.company;
    } else {
        const selCust = customer.affiliations.find(aff => aff.id === customer.selectedCustomerId);
        selectedCompanyName = selCust.company ? selCust.company
            : (selCust.first_name ? `${selCust.first_name} ${selCust.last_name ? selCust.last_name.charAt(0) : ''}` : null);
    }

    const handleSwitchCompany = ownerId => {
        dispatch(setActiveParentCustomerCall(ownerId));
    };

    const toggleCreatePopupShow = () => {
        setCreatePopupShow(!showCreatePopup);
    };

    function selectApp(appId) {
        dispatch(setSelectedApp(appId));
    }

    const handleLogout = () => {
        dispatch(resetAffiliationMemberStore(true));
        fetch('/logout', { method: 'POST' }).then(() => {
            window.location.href = '/logout';
        });
    };


    return (
        <>
            <Container>
                {isFetched && (
                    <ErrorWrapper listenToErrors={[APPS_CALL]} errorScreen={<HeaderSeparator />}>
                        <HeaderDropdown>
                            <DropDown
                                withIcon
                                iconColor={colors.blue6}
                                noOverflowBody
                                trigger={(
                                    <HeaderDropdownTrigger fixedSize>
                                        <IconWrapper color={colors.blue6}>
                                            <AppSvg />
                                        </IconWrapper>
                                        <span>{selectedAppName || 'Select app'}</span>
                                    </HeaderDropdownTrigger>
                                )}
                            >
                                <AppUl>
                                    {userAppNames.length > 0 && (
                                        <>
                                            {userAppNames.map(({ id, name, region }) => (
                                                <AppLi key={id}
                                                    onClick={() => selectApp(id)}
                                                >
                                                    <AppName>{name}</AppName>
                                                    <AppRegion>{region}</AppRegion>
                                                </AppLi>
                                            ))}
                                            <HorizontalSeparator />
                                        </>
                                    )}
                                    <AppLi onClick={toggleCreatePopupShow}>
                                        <Row align="center">
                                            <IconWrapper>
                                                <Plus />
                                            </IconWrapper>
                                            <CreateAppText className="nowrap">Create Application</CreateAppText>
                                        </Row>
                                    </AppLi>
                                </AppUl>
                            </DropDown>
                        </HeaderDropdown>
                        <HeaderSeparator />
                    </ErrorWrapper>
                )}
                <HeaderRightSide>
                    {customerIsFetched && (
                        <ErrorWrapper
                            listenToErrors={[CUSTOMER_CALL]}
                            /* eslint-disable-next-line react/jsx-no-useless-fragment */
                            errorScreen={<></>}
                            errorScreenContainerStyles="margin: 0 5px"
                        >
                            {customer.email && (
                                <>
                                    {customerSubscription && customerSubscription.state === subscriptionState.TRIAL && (
                                        <>
                                            <Row align="center" margin="0 5px">
                                                <ExpiredSpan>
                                                    {customerSubscription.expires_in_days > 0
                                                        ? `${customerSubscription.expires_in_days} day
                                                        ${customerSubscription.expires_in_days > 1 ? 's' : ''} left in your trial`
                                                        : 'Your trial has expired.'}
                                                </ExpiredSpan>
                                                <Link className="button blue filled header_upgrade_button"
                                                    to={`/${routeNames.BILLING}/${subRoutNames.SUBSCRIPTION}/${subRoutNames.PLANS}`}
                                                >
                                                    <span>Upgrade</span>
                                                    {/* <InfoSvg/> */}
                                                </Link>
                                            </Row>
                                            <HeaderSeparator />
                                        </>
                                        /* <div>
                                              <span>Days left {customerSubscription.expires_in_days}</span>
                                         </div>*/
                                    )}

                                    {/*  <Docs>
                                        <a href="https://docs.sceyt.com" rel="noreferrer noopener"
                                           target="_blank"
                                        >Docs
                                        </a>
                                    </Docs>
                                    */}
                                    {/* <HeaderDropdown>
                                        <DropDown
                                            minWith="340px"
                                            trigger={(
                                                <HeaderDropdownTrigger notificationTrigger>
                                                    <NewNotification/>
                                                    <NotificationSvg/>
                                                </HeaderDropdownTrigger>
                                            )}
                                        >
                                            <div>
                                                <NotificationDropdownHeader>
                                                    Notifications
                                                </NotificationDropdownHeader>
                                                    <HorizontalSeparator/>
                                                    <NotificationCard>
                                                        <NotificationTitle>Create application</NotificationTitle>
                                                        <NotificationDesctiption>
                                                            Check out suggestions to improve the security
                                                            of your account. You can find all your suggestions
                                                        </NotificationDesctiption>
                                                    </NotificationCard>
                                            </div>
                                        </DropDown>
                                    </HeaderDropdown> */}

                                    <HeaderDropdown isAvatar>
                                        <DropDown
                                            minWith="240px"
                                            trigger={(
                                                <HeaderDropdownTrigger>
                                                    <Row align="center">
                                                        <Col>
                                                            <UserName align="right">
                                                                {customer.first_name ? `${customer.first_name} ${customer.last_name
                                                                    ? customer.last_name.charAt(0) : ''}` : ''}
                                                                <OpenArrowSvg />
                                                            </UserName>
                                                            <CompanyName align="right">{selectedCompanyName}</CompanyName>
                                                        </Col>
                                                        {/* <Avatar className="avatar" size="s" color="#FBB019">
                                                            <AvatarText>{customer.first_name.charAt(0)}</AvatarText>
                                                        </Avatar>*/}
                                                    </Row>
                                                </HeaderDropdownTrigger>
                                            )}
                                        >
                                            <div>
                                                <UserInfo>
                                                    <Avatar size="s" className="avatar" color="#4F6AFF">
                                                        <AvatarText>{customer.first_name.charAt(0)}</AvatarText>
                                                    </Avatar>
                                                    <UserInfoCont maxWidth="calc(100% - 45px)">
                                                        <UserName>
                                                            {customer.first_name ? `${customer.first_name} ${customer.last_name
                                                                ? customer.last_name.charAt(0) : ''}` : ''}
                                                        </UserName>
                                                        <CompanyName>{selectedCompanyName}</CompanyName>
                                                    </UserInfoCont>
                                                </UserInfo>
                                                <HorizontalSeparator />
                                                <DropdownOptionsUl>
                                                    {(customer.affiliations.length > 0 || !customer.company) && (
                                                        <OrganizationsItem>
                                                            <ProfileMenuItem padding="8px 6px 8px 18px">
                                                                <SwitchIcon />
                                                                Switch account
                                                                <OrganizationsItemArrow />
                                                                <OrganizationsList>
                                                                    {customer.company
                                                                        ? (
                                                                            <DropdownOptionLi onClick={() => handleSwitchCompany(customer.id)}>
                                                                                {customer.id === customer.selectedCustomerId
                                                                                    ? <CheckIcon /> : null} {customer.company}
                                                                            </DropdownOptionLi>
                                                                        )
                                                                        : (
                                                                            <DropdownOptionLi
                                                                                onClick={() => handleSwitchCompany(customer.id)}
                                                                            >
                                                                                {customer.id === customer.selectedCustomerId
                                                                                    ? <CheckIcon /> : null}
                                                                                {customer.first_name
                                                                                    ? `${customer.first_name} ${customer.last_name
                                                                                        ? customer.last_name.charAt(0) : ''}` : ''}
                                                                            </DropdownOptionLi>
                                                                        )}
                                                                    <HorizontalSeparator />
                                                                    {customer.affiliations.map(affiliation => (
                                                                        <DropdownOptionLi padding="8px 6px 8px 18px"
                                                                            onClick={() => handleSwitchCompany(affiliation.id)} key={affiliation.id}
                                                                        >
                                                                            {affiliation.id === customer.selectedCustomerId ? <CheckIcon /> : null}
                                                                            <AffiliationName>{affiliation.company}</AffiliationName>
                                                                        </DropdownOptionLi>
                                                                    ))}
                                                                    {/* {!customer.company && (
                                                                        <>
                                                                            <HorizontalSeparator/>
                                                                            <CreateCompanyItem>
                                                                                <Row align="center">
                                                                                    <IconWrapper>
                                                                                        <Plus/>
                                                                                    </IconWrapper>
                                                                                    <CreateAppText className="nowrap">Create Company</CreateAppText>
                                                                                </Row>
                                                                            </CreateCompanyItem>
                                                                        </>
                                                                    )} */}
                                                                </OrganizationsList>
                                                            </ProfileMenuItem>
                                                        </OrganizationsItem>
                                                    )}
                                                    <Link to={`/${routeNames.USER_ACCOUNT}`} >
                                                        <ProfileMenuItem padding="8px 6px 8px 18px"><ProfileIcon />Your account</ProfileMenuItem>
                                                    </Link>
                                                    <Link to={`/${routeNames.MEMBERS}`}>
                                                        <ProfileMenuItem padding="8px 6px 8px 18px"><UsersIcon /> Members</ProfileMenuItem>
                                                    </Link>
                                                    {/* <Link to={`${routeNames.SECURITY}`}>
                                                        <DropdownOptionLi>Security</DropdownOptionLi>
                                                    </Link>*/}
                                                    <Link to={`/${routeNames.BILLING}`}>
                                                        <ProfileMenuItem padding="8px 6px 8px 18px"><PaymentIcon /> Billing</ProfileMenuItem>
                                                    </Link>
                                                    <Link to={`/${routeNames.SECURITY}`}>
                                                        <ProfileMenuItem padding="8px 6px 8px 18px"><SecurityIcon /> Security</ProfileMenuItem>
                                                    </Link>
                                                    <a rel="noreferrer" target="_blank"
                                                        href="https://sceyt.com/contact-support"
                                                    >
                                                        <ProfileMenuItem padding="8px 6px 8px 18px"> <SupportIcon /> Get help</ProfileMenuItem>
                                                    </a>
                                                    <HorizontalSeparator />
                                                    {/* <a href="/logout" target="_self"> */}
                                                    <ProfileMenuItem padding="8px 6px 8px 18px" onClick={handleLogout}>Sign out</ProfileMenuItem>
                                                    {/* </a> */}
                                                </DropdownOptionsUl>
                                            </div>
                                        </DropDown>
                                    </HeaderDropdown>
                                </>
                            )}
                        </ErrorWrapper>
                    )}
                </HeaderRightSide>
            </Container>

            {showCreatePopup && (
                <CreateEditApp togglePopupShow={toggleCreatePopupShow} />
            )}
        </>
    );
}

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  min-height: 60px;
    //background-color: ${colors.blue6};
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(21, 7, 38, 0.14);
  padding: 0 30px 0 20px;

  @media ${device.tablet} {
    min-height: 54px;
    height: 54px;
    padding: 0 15px;
  }
`;

const ExpiredSpan = styled.span`
  white-space: nowrap;
  font-size: 0.812rem;
  font-weight: 400;
  //color: white;
  color: ${colors.blue6};
  margin-right: 15px;
`;

const OrganizationsList = styled.ul`
  position: absolute;
  min-width: 220px;
  visibility: hidden;
  opacity: 0;
  top: calc(100% - 18px);
  right: calc(100% - 20px);
  transition: all 0.1s;
  background: #FFFFFF;
  border: 0.5px solid rgba(31, 35, 60, 0.08);
  box-sizing: border-box;
  box-shadow: 0 0 2px rgba(31, 35, 60, 0.08), 0 2px 6px rgba(31, 35, 60, 0.14);
  border-radius: 4px;
  padding: 11px 0 6px;

  ${DropdownOptionLi} {
    padding: 6px 12px 6px 32px;
    display: flex;
    align-items: center;
    & > svg {
      position: absolute;
      left: 12px;
      }
    }
  }
`;

const UserInfoCont = styled(Col)`
  justify-content: center;
`;

const AffiliationName = styled.span`
  display: inline-block;
  white-space: nowrap;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OrganizationsItem = styled.div`
  position: relative;

  & :hover {
    ${OrganizationsList} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const ProfileMenuItem = styled(DropdownOptionLi)`
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 12px;
    }
`;

const OrganizationsItemArrow = styled(ArrowRight)`
  margin-right: 6px !important;
  margin-left: auto;
`;


const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  width: 18px;
  height: 30px;
  margin-right: 12px;

  ${props => props.color && `color: ${props.color}`}
`;

const AppName = styled.span`
  line-height: 1.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${colors.blue6};

  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AppRegion = styled.span`
  line-height: 1.125rem;
  font-size: 0.75rem;
  font-weight: 400;
  color: ${colors.gray4};
`;

const CreateAppText = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${colors.blue1};
`;

const AppUl = styled(DropdownOptionsUl)`
  margin: 6px 0 !important;
`;

const AppLi = styled(DropdownOptionLi)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 2px 25px;
  height: 48px;

  &:hover {
    svg path {
      fill: ${colors.blue1};
    }

    ${AppName} {
      color: ${colors.blue1};
    }
  }
`;

const Plus = styled(PlusSvg)`
  & > path {
    fill: ${colors.blue1};
  }
`;

const HeaderRightSide = styled.div`
  max-width: calc(100% - 100px);
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //color: #FFFFFF;
  color: ${colors.blue6};
  margin-left: auto;

  & .header_upgrade_button {
    padding: 7px 14px;
  }
`;

/*
const Docs = styled.div`
  display: flex;
  justify-content: center;

  a {
    //color: white;
    color: ${colors.blue6};
    line-height: 1.75rem;
    font-size: 0.937rem;
    font-weight: 400;
    padding: 0 20px;
  }
`;
*/

const HeaderDropdown = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  //height: 100%;
  padding-right: 2px;

  ${props => (props.isAvatar ? `
        margin-left: 10px;
        
        &:hover, .dropdown-trigger.open {
            .avatar {
               // box-shadow: 0 0 0px 3px #f4f5ff, 0 0px 0px 4px rgb(0 0 0 / 10%)
            }
        }
        
        .dropdown-body {
            max-height: 300px;
        }
    ` : `
        margin: 0 5px;

        &:hover, .dropdown-trigger.open {
            background-color: #F4F5FF;
            border-radius: 4px;
        }
    `)}
`;

const UserName = styled.h3`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.937rem;
  line-height: 1.25rem;
  color: ${colors.blue6};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: ${props => props.align};
  max-width: 100%;
  margin: 0 15px 0 0;

  > svg {
    margin-left: 7px;
    transform: translate(0px, -1px);
  }
`;

const AvatarText = styled.span`
  font-weight: 600;
`;

/* const NewNotification = styled.span`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 7px;
  height: 7px;
  background-color: #FD575A;
  border-radius: 50%;
`;

const NotificationDropdownHeader = styled.h3`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin: 0 18px 9px;
  color: #1F233C;
`;

const NotificationCard = styled.div`
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 18px;
  padding: 14px 12px
`;

const NotificationTitle = styled.h4`
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.1px;
  margin: 0 0 10px;

  color: #1F233C;
`;

const NotificationDesctiption = styled.p`
  font-size: 13px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  color: ${colors.blue7};
`; */
const HeaderDropdownTrigger = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  padding: ${props => (props.notificationTrigger ? '9px 10px' : '7px 0 7px 10px')};

  span {
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 0.937rem;
    line-height: 1.25rem;
    //color: white;
    color: ${colors.blue6};
    text-align: left;

    ${props => props.fixedSize && 'width: 120px;'}
  }
;

  ${AvatarText} {
    color: #fff;
  }
`;

const HeaderSeparator = styled.div`
  background-color: rgba(255, 255, 255, 0.32);
  border: none;
  width: 1px;
  height: 28px;
  margin: 0 10px;
`;

const HorizontalSeparator = styled.hr`
  background-color: ${colors.gray1};
  border: none;
  height: 1px;
  margin: 6px 0;
`;

const UserInfo = styled(Row)`
  max-width: 200px;
  padding: 0 6px 6px 18px;
`;

const CompanyName = styled.h6`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.812rem;
  line-height: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 280px;
  margin: ${props => (props.align === 'right' ? '0 0 0 12px;' : '0')};
  text-align: ${props => props.align};
  ${props => props.align === 'right' && 'transform: translate(-32px, 0px)'};
  color: rgba(31, 35, 60, 0.8);
`;