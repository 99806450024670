import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import AppRoutes from './routes';
import Header from './components/header';
import Sidebar from './components/sidebar';
import { appsCall } from './store/apps/actions';
import { regionsCall } from './store/regions/actions';
import { CUSTOMER_CALL, customerCall, setRequestsLimit } from './store/customer/actions';
import { customerDataSelector, requestsLimitSelector } from './store/customer/selectors';
import ErrorPopup from './components/complex/popups/error';
import ExpirePopup from './components/complex/popups/expired';
import Loader from './components/complex/loader';
import ErrorWrapper from './components/complex/error-wrapper';
import TwoFactorAuthSuggestion from './components/pages/security/two-factor-auth-suggestion';
import { GlobalStyles, Body, MainContainer } from './components/UI';

import './helpers/prototypes';
import './assets/fonts/fonts.css';
import { CUSTOMER_STATE, subscriptionState } from './helpers/constants';
import RequestsLimit from './components/pages/requestsLimit';
import { useDidUpdate } from './hooks';

export default function App() {

    const dispatch = useDispatch();
    const location = useLocation();
    const [showSidebar, setSidebarShow] = useState(true);
    const { isFetched: isFetchedCustomer, customer } = useSelector(customerDataSelector, isEqual);
    const requestsLimit = useSelector(requestsLimitSelector, isEqual);
    const subscriptionExpired = customer.state === CUSTOMER_STATE.suspended && (customer.subscription && customer.subscription.state === subscriptionState.TRIAL);
    const checkRequestsLimit = () => {
        const limitTime = localStorage.getItem('requestsLimitTime');
        if (limitTime) {
            const timeout = limitTime - Date.now() / 1000;
            if (timeout > 0) {
                dispatch(setRequestsLimit(timeout));
            } else {
                localStorage.removeItem('requestsLimitTime');
            }
        }
    };
    useEffect(() => {
        dispatch(customerCall());
    }, []);

    useDidUpdate(() => {
        dispatch(appsCall());
        dispatch(regionsCall());
    }, [customer.selectedCustomerId]);

    useEffect(() => {
        checkRequestsLimit();
    }, []);

    const isNotFound = useMemo(() => location.pathname === '/not-found', [location.pathname]);
    const StylesMemo = useMemo(() => (
        <>
            {/* <GlobalFonts/>*/}
            <GlobalStyles/>
        </>
    ), []);
    const HeaderMemo = useMemo(() => <Header/>, []);
    const ErrorPopupMemo = useMemo(() => <ErrorPopup/>, []);
    const ExpirePopupMemo = useMemo(() => <ExpirePopup subscriptionExpired={subscriptionExpired}/>, [subscriptionExpired]);

    return (
        <>
            <TwoFactorAuthSuggestion />
            <MainContainer>
                {requestsLimit && <RequestsLimit limitExpires={requestsLimit.timeout}/>}
                {StylesMemo}
                {!isNotFound && <Sidebar setSidebarShow={setSidebarShow} showSidebar={showSidebar}/>}
                <Body showSidebar={showSidebar} isNotFound={isNotFound}>
                    {!isFetchedCustomer || !customer.email ? (
                        <Loader/>
                    ) : (
                        <ErrorWrapper listenToErrors={[CUSTOMER_CALL]}>
                            {HeaderMemo}
                        <AppRoutes fullWidth={isNotFound} showSidebar={showSidebar}/>
                            {ExpirePopupMemo}
                        </ErrorWrapper>
                    )}
                </Body>
                {ErrorPopupMemo}
            </MainContainer>
        </>
    );
}