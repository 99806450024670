export const colors = {
    white: '#fff',

    blue1: '#4F6AFF',
    blue2: '#3751FF',
    blue3: 'rgba(55, 81, 255, 0.24)',
    blue4: '#283379',
    blue5: '#172268',
    blue6: '#1F233C',
    blue7: '#383B51',
    blue8: '#9AABFB',
    blue9: '#2d44bf',
    midnightBlue: '#060A26',

    gray0: '#EFF1FF',
    gray1: '#DFE0EB',
    gray2: '#D7D8E3',
    gray3: '#BBBBC1',
    gray4: '#838493',
    gray5: '#F7F8FC',
    gray6: '#676A7C',
    gray7: '#626577',
    gray8: '#F4F5FF',

    red1: '#FD575A',
    red2: '#ED4B4E',

    green1: '#31B988',
    green2: '#2ba57a',
    green3: '#337450',

    yellow1: '#FCD36E',
    yellow2: '#FFEBC2',

    titleTransparent: 'rgba(31, 35, 60, 0.65)'
};

export const userAvatarColors = [
    '#FF3E74', '#4F6AFF', '#FBB019', '#00CC99', '#9F35E7', '#63AFFF'
];

export const size = {
    // mobileS: '320px',
    // mobileM: '375px',
    // mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    max: '2560px'
};

export const device = {
    // mobileS: `(min-width: ${size.mobileS})`,
    // mobileM: `(min-width: ${size.mobileM})`,
    // mobileL: `(min-width: ${size.mobileL})`,
    tablet: `screen and (max-width: ${size.tablet})`,
    laptop: `screen and (max-width: ${size.laptop})`,
    laptopL: `screen and (max-width: ${size.laptopL})`,
    max: `screen and (max-width: ${size.max})`
};