import { errorsConstant } from './constants-errors';


export default class NetworkError {
    constructor(err) {
        // this.message = customMessage || customMessageObject.other || '';
        const customMessageObject = errorsConstant[err.failedAction.type];
        this.message = err.error.response.data.m.message || err.error.message || '';
        this.name = 'Failed';
        this.buttonName = customMessageObject.btnText || 'Retry';
        this.closeOnFail = customMessageObject.closeOnFail || false;
        this.isFatal = customMessageObject.isFatal || false;
        if (err.error && err.error.response) {
            if (err.error.response.status === 403) {
                this.name = 'Access Restricted';
                this.message = 'You don’t have permission for this action.';
            } else {
                if (err.error.response.statusText && !err.error.response.data.m.message) {
                    this.message += `\n ${err.error.response.statusText}`;
                }
                if (err.error.response.status && !err.error.response.data.m.message) {
                    this.message += ` (Code ${err.error.response.status})`;
                }
            }
            if (err.error.response.data && err.error.response.data.c) {
                const { c } = err.error.response.data;
                if (customMessageObject[c]) {
                    /* if (typeof customMessageObject[c].message === 'string') {
                        this.message += `\n ${customMessageObject[c].message}`;
                    } else {
                        const errorForCase = customMessageObject[c].message(err.error);
                        if (errorForCase) {
                            this.message += `\n ${errorForCase}`;
                        } else {
                            this.message += `\n ${customMessageObject.message} (Code ${c})`;
                        }
                    }*/

                    // Error button text
                    if (customMessageObject[c].btnText) {
                        this.buttonName = customMessageObject[c].btnText;
                    }

                    // Action in case of error
                    if (customMessageObject[c].closeOnFail) {
                        this.closeOnFail = customMessageObject[c].closeOnFail;
                    }

                    // Error is fatal (get actions)
                    if (customMessageObject[c].isFatal) {
                        this.isFatal = customMessageObject[c].isFatal;
                    }
                }/* else {
                            this.message += `\n ${customMessageObject.message} (Code ${c})`;
                        }*/
            }/* else {
                    if (err.error.response.statusText) {
                        this.message += `\n ${err.error.response.statusText}`;
                    }
                    if (err.error.response.status) {
                        this.message += ` (Code ${err.error.response.status})`;
                    }
                }*/
        } else {
            this.message = `\n  ${this.message || 'Unknown network error'}`;
        }
    }
}