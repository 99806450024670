import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import {
    StyledCloseSvg
} from '../../../UI';
import { colors } from '../../../UI/constants';
import { customerSuggestionsForTwoFactor } from '../../../../store/customer/actions';
import { customerSuggestionForTwoFactorSelector } from '../../../../store/customer/selectors';
import Link from '../../../common/link';

export default function TwoFactorAuthSuggestion() {
    const dispatch = useDispatch();
    const suggestionsForTwoFactor = useSelector(customerSuggestionForTwoFactorSelector, isEqual);

    return (
        <Banner className={suggestionsForTwoFactor === true ? 'show-suggestion-popup' : 'hide-suggestion-popup'}>
            <Text>
                Secure your account with an extra layer of protection:{' '}
                <Link to="/security">Enable Two-Factor Authentication</Link>.
            </Text>
            <StyledCloseSvg
                top="0px"
                right="25px"
                onClick={() => dispatch(customerSuggestionsForTwoFactor(false))}
            />
        </Banner>
    );
}

const Banner = styled.div`
    background-color: ${colors.yellow2};
    display: flex;
    align-items: center;
    font-size: 14px;
    overflow: hidden;
    transition: height 0.5s;
`;

const Text = styled.p`
    font-weight: bold;
    margin: 0 auto;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    color: ${colors.blue6};
    a {
        color: ${colors.blue6};
        text-decoration: underline;
    }
`;